<template>
  <div>
    <IncidentFrom2 v-if="flag" openType="2" :eventId="eventId" :fromVal="fromVal"></IncidentFrom2>
  </div>
</template>

<script>
import IncidentFrom2 from '@/components/myForm.vue';
export default {
  components: { IncidentFrom2 },
  data() {
    return {
      fromVal: {},
      flag: false,
      eventId:''
    }
  },

  created() {
    
    this.eventId=this.$route.query.id;
    this.flag = true;
  },
  methods: {

  },
}
</script>

<style lang="scss" scoped></style>