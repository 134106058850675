<template>
  <div>
    <myForm openType="1"></myForm>
  </div>
</template>

<script>

import myForm from '../../components/myForm.vue';
export default {
  components: {
    myForm
  },
  data() {
    return {

    }
  },
  methods: {
    
  },

}
</script>
<style lang="scss" scoped></style>