<template>
  <div style="background-color: #f5f6f4;">
    <!-- <div class="header">
     
    </div> -->
    <div class="content">

      <van-list v-model="loading" :finished="finished" finished-text="没有更多了" @load="onLoad">
        <div class="card" v-for="(item ) in list" :key="item.id" @click="toFrom(item.id)">
          <div class="cell_item cell_item1">
            <!-- 按填报时间?-->
            <span class="cell_text cell_text1">{{ item.createTime | setTime(that) }}</span>
          </div>
          <div class="cell_item cell_item2">
            <span class="cell_text">{{ item.relatedProjects }}</span>
            <van-icon name="arrow" />
          </div>
        </div>
      </van-list>

    </div>
  </div>
</template>

<script>
import axios from 'axios';
export default {
  data() {
    return {
      list: [],
      loading: false,
      finished: false,
      that: this,
      userInfo:'',
      phoneId:''
    }
  },
  created() {
    this.phoneId=this.$route.query.phone||'无'
  },
  methods: {
    getList() {
      // this.userInfo = JSON.parse(localStorage.getItem('userInfo'))||''
      // const openId = this.userInfo.openid || ''
      this.$http(`/api/salaryArrears/list?phone=${this.phoneId}`).then(res => {//?wxOpenid=${openId}
        // console.log('事件列表', res);
        this.loading = false;
        this.finished = true;
        this.list = res.rows.length > 0 ? res.rows : []
      }).catch(err => {
        // console.log('事件列表err', err);
        this.loading = false;
        this.finished = true;
      })
    },

    onLoad() {
      this.getList()
    },

    toFrom(id) {
      this.$router.push({ path: '/checkForm', query: { id: id } });
    }
  },
  beforeCreate() {

  },
  filters: {

    setTime(val, vue) {
      return vue.$parseTime(val, '{y}-{m}-{d}')
    }
  }
}
</script>

<style lang="scss" scoped>
body {
  background-color: #f5f6f4;
}

.content {
  min-height: calc(100vh - 40px);
  width: 90vw;
  margin: 0 auto;
  padding-bottom: 40px;

  // box-shadow: 0 0 6px 1px #dbdbdb;

}

.van-list {
  padding-top: 20px;
}

.card {
  height: 120px;
  border-radius: 8px;
  margin: 15px auto;
  padding-left: 20px;
  // box-shadow: 0 0 6px 1px #dbdbdb;
  background-color: #fff;
}

.van-cell::after {
  border-bottom: 0;
  border-bottom-width: 1.5px;
  // border-bottom-color: #ebedf0;
  border-bottom-style: dashed;
}

.cell_item {
  display: flex;
  font-weight: bolder;
  font-size: 16px;
  letter-spacing: 1px;
  border-bottom: 1px solid #e0e0e0;
}

.cell_item1 {
  height: 40%;
  align-items: center;
  // white-space: nowrap;
}

.cell_text {
  width: 100%;
  font-weight: bolder;
  font-size: 16px;
  white-space:pre-wrap;
  display: block;
}



.cell_item2 {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 60%;
  align-items: center;

  :nth-child(1) {
    font-size: 16px;
  }
}

.van-icon-arrow {
  padding-right: 10px;
  opacity: 0.4;
}

.cell_text {
  font-weight: bold;
  margin-left: 10px;
  color: #494545;
  word-break:  break-all;
}

.cell_text1 {
  font-size: 14px;
  font-weight: 500;
}
</style>