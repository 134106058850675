import Vue from 'vue'
import VueRouter from 'vue-router'
// 首页 
import Home from '../views/home/index.vue'
import formIndex from '../views/index/index.vue'
import addForm from '../views/addForm/index.vue'
import checkForm from '../views/checkForm/index.vue'
import Record from '../views/Record/index.vue'
Vue.use(VueRouter)
const routes = [
  {
    path: '/',
    name: 'home',
    component: Home,
    meta: {
      title: '首页'
    }
    // component: () => import(/* webpackChunkName: "about" */ '../views/About.vue')
  },

  {
    path: '/index',
    name: 'home',
    component: Home,
    meta: {
      title: '首页'
    }
  },
  {
    path: '/form',
    name: 'formIndex',
    component: formIndex,
    meta: {
      title: '填写个人信息'
    }
    // component: () => import(/* webpackChunkName: "about" */ '../views/About.vue')
  },
  {
    path: '/addForm',
    name: 'addForm',
    component: addForm,
    meta: {
      title: '填写详细信息'
    }
  },
  {
    path: '/record',
    name: 'Record',
    component: Record,
    meta: {
      title: '历史投诉记录'
    }
  },
  {
    path: '/checkForm',
    name: 'checkForm',
    component: checkForm,
    meta: {
      title: '历史投诉记录'
    }
  },
  



]
console.log(` 2021年12月14日 01点53分 `, process.env.BASE_URL);
const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

router.beforeEach((to, from, next) => {
  document.title = to.meta.title
  next()
})

export default router
