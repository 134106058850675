import Vue from "vue";
import ElementUI from "element-ui";
import "element-ui/lib/theme-chalk/index.css";
import "@/assets/style/index.scss";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import http from "@/utils/http";
import Storage from "@/utils/localstorage.js";
import timerStorage from "@/utils/timerStorage.js";
import Vant from 'vant';
import 'vant/lib/index.css';

Vue.config.productionTip = false;
Vue.use(ElementUI);
Vue.use(Vant);
Vue.prototype.$parseTime = function parseTime(time, pattern) {
	if (arguments.length === 0 || !time) {
		return null
	}
	const format = pattern || '{y}-{m}-{d} {h}:{i}:{s}'
	let date
	if (typeof time === 'object') {
		date = time
	} else {
		if ((typeof time === 'string') && (/^[0-9]+$/.test(time))) {
			time = parseInt(time)
		} else if (typeof time === 'string') {
			time = time.replace(new RegExp(/-/gm), '/').replace('T', ' ').replace(new RegExp(/\.[\d]{3}/gm), '');
		}
		if ((typeof time === 'number') && (time.toString().length === 10)) {
			time = time * 1000
		}
		date = new Date(time)
	}
	const formatObj = {
		y: date.getFullYear(),
		m: date.getMonth() + 1,
		d: date.getDate(),
		h: new Date().getHours(),
		i: new Date().getMinutes(),
		s: new Date().getSeconds(),
		a: date.getDay()
	}
	const time_str = format.replace(/{(y|m|d|h|i|s|a)+}/g, (result, key) => {
		let value = formatObj[key]
		// Note: getDay() returns 0 on Sunday
		if (key === 'a') { return ['日', '一', '二', '三', '四', '五', '六'][value] }
		if (result.length > 0 && value < 10) {
			value = '0' + value
		}
		return value || 0
	})
	return time_str
};
Vue.prototype.$http = http;
Vue.prototype.$Storage = Storage;
Vue.prototype.$TimerStorage = timerStorage;
new Vue({
	router,
	store,
	render: (h) => h(App),
}).$mount("#app");
