import Vue from 'vue'
import Vuex from 'vuex'
import Storage from "@/utils/localstorage.js";

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    // 考核计划内容
    planData: Storage.get('planData'),
    // 当前正在审核人的信息
    currentUser: Storage.get('currentUser'),
    // 当前正在审核人的序列
    currentUserIndex: Storage.get('currentUserIndex'),
    // 评价评分ABCD
    chooseOption: Storage.get('chooseOption')
  },
  getters: {
    planData: state => {
      return state.planData
    },
    examinePlanUserList: state => {
      console.log(` ==== Turbo Console  state`, state);
      return state.planData.examinePlanUserList
    },
    currentUser: state => {
      return state.currentUser
    },
    currentUserIndex: state => {
      return state.currentUserIndex
    },
    chooseOption: state => {
      return state.chooseOption
    },
  },
  mutations: {
    SetPlanData(state, data) {
      // 存入基本数据
      state.planData = data
      Storage.set('planData', data)
    },
    SetCurrentUser(state, data) {
      state.currentUser = data
      Storage.set('currentUser', data)
    },
    SetCurrentUserIndex(state, data) {
      state.currentUserIndex = data
      Storage.set('currentUserIndex', data)
    },
    SetChooseOption(state, data) {
      // 存入基本数据
      state.chooseOption = data
      Storage.set('chooseOption', data)
    },
  },
  actions: {
  },
  modules: {
  }
})
