<template>
	<div id="app">
		<router-view />
	</div>
</template>

<script>
var useragent = navigator.userAgent;
var ua = navigator.userAgent.toLowerCase();

var isWeixin = ua.indexOf('micromessenger') != -1;
if (!isWeixin) {
	window.location.href = "https://open.weixin.qq.com/connect/oauth2/authorize?appid=888"
}

</script>
<style lang="scss">
* {
	padding: 0;
	margin: 0;
}

body {
	/* background: -webkit-linear-gradient(left top, red , blue); Safari 5.1 - 6.0 */
	/* background: -webkit-linear-gradient(left top, #003CE5 , #2A9FFF); Safari 5.1 - 6.0 */
}

#app {
	font-family: Avenir, Helvetica, Arial, sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	color: #2c3e50;
	max-width: 800px;
	margin: 0 auto;
	padding: 0;
	/* background-color: #ebf6fe; */
}

#nav {
	padding: 30px;
}

#nav a {
	font-weight: bold;
	color: #2c3e50;
}

#nav a.router-link-exact-active {
	color: #42b983;
}

.page-container-title {
	padding: 8px 16px;
	background-color: #ecf8ff;
	border-radius: 4px;
	border-left: 5px solid #50bfff;
	margin: 20px 0;
}

.el-form-item__content {
	width: 58vw;
	margin-left: 25vw;

	// input {
	// 	border: 0;
	// 	border-bottom: 1px solid #dbdbdb;
	// 	border-radius: 0;
	// }
}

.el-form-item__label {
	width: 25vw;
}

.el-input__inner {
	height: 35px;
}


.el-form-item__error {
	padding-top: 0;
}
</style>
