<!-- 进入首页 -->
<template>
	<div id="index-page" class='index-page'>
		<div class="index-content margin-top-lg">
			<div class="index-logo">
				<!-- <img src="../../assets/index-logo.jpg" alt="" /> -->
				<img style="width: 60px;" src="../../assets/logo-2.png" alt="" />
			</div>
			<div class="page-header-title">
				欠薪欠费投诉系统
			</div>
			<div class="form_box">
				<div class="form_title">
					请输入投诉人信息：
				</div>
				<el-form :model="ruleForm" :rules="rules" ref="ruleForm" class="demo-ruleForm">

					<el-form-item style="margin-bottom: 13px;" label="姓名" prop="fullName">
						<el-input v-model="ruleForm.fullName" placeholder="请输入你的名字"></el-input>
					</el-form-item>
					<el-form-item style="margin-bottom: 13px;" label="电话" prop="phone">
						<el-input v-model="ruleForm.phone" placeholder="请输入你的联系电话"></el-input>
					</el-form-item>
					<el-form-item style="margin-bottom: 13px;" label="身份证号" prop="idCard">
						<el-input v-model="ruleForm.idCard" placeholder="请输入你的身份证号"></el-input>
					</el-form-item>
				</el-form>
				<div class="form_title  record" style="position: relative;width: 100vw;height: 40px;">
					<span @click="toRecords" style="position: absolute; right: calc(100vw - 83vw - 2px)">
						历史投诉记录
						<i class="el-icon-arrow-right el-icon--right"></i>
					</span>
				</div>
			</div>

			<div class="flex justify-center  bottom_box">
				<!-- <el-button type="primary" icon="el-icon-right" @click="next">下一步</el-button> -->
				<!-- <el-button type="primary" icon="el-icon-right" @click="next">下一步</el-button> -->
				<el-button type="primary" @click="next">下一步<i class="el-icon-right"></i></el-button>
			</div>


		</div>
	</div>
</template>

<script>
//这里可以导入其他文件（比如：组件，工具js，第三方插件js，json文件，图片文件等等）
//例如：import 《组件名称》 from '《组件路径》';
import axios from 'axios';
export default {
	//import引入的组件需要注入到对象中才能使用
	components: {
	},
	data() {
		var checkPhone = (rule, value, callback) => {
			if (!value) {
				return callback(new Error('手机号不能为空'));
			} else {
				const reg = /^1[3|4|5|7|8][0-9]\d{8}$/
				if (reg.test(value)) {
					callback();
				} else {
					return callback(new Error('请输入正确的手机号'));
				}
			}
		};
		var checkIdCard = (rule, value, callback) => {
			if (!value) {
				callback();
			} else {
				const reg = /^[1-9]\d{5}(19|20)\d{2}(0\d|1[0-2])([0-2]\d|3[0-1])\d{3}([0-9]|X)$/
				if (reg.test(value)) {
					callback();
				} else {
					return callback(new Error('请输入正确的身份证号码'));
				}
			}
		};
		


		return {
			// 阅读规则
			centerDialogVisible: false,
			// 表单数据
			infoData: {
				notice: undefined
			},
			ruleForm: {
				fullName: '',
				phone: '',
				idCard:'',
			},
			rules: {
				fullName: [
					{ required: true, message: '请输入您的姓名', trigger: 'blur' },
				],
				phone: [
					{
						required: true, message: '请输入正确手机号', trigger: 'blur', validator: checkPhone
					}
				],
				idCard:[{
					 trigger: 'blur', validator: checkIdCard
				}]
			}

		};
	},
	//监听属性 类似于data概念
	computed: {},
	//监控data中的数据变化
	watch: {},
	//生命周期 - 创建完成（可以访问当前this实例）
	created() {

	},
	//生命周期 - 挂载完成（可以访问DOM元素）
	mounted() {
		// this.initData();
		let StorageForm = JSON.parse(localStorage.getItem('formVal'));
		if (StorageForm) {
			this.ruleForm = StorageForm
		}

	},
	//方法集合
	methods: {


		//提交表单
		submitForm() {
			this.$refs["ruleForm"].validate((valid) => {
				if (valid) {

					if (this.ruleForm) {
						localStorage.setItem('formVal', JSON.stringify(this.ruleForm))
					}

					this.$router.push('/addForm');
				} else {
					this.$message.error('请先完善个人信息！');
					return false;
				}
			});
		},
		next() {
			// this.centerDialogVisible = false
			this.submitForm()
			// this.$router.push('/addFrom')
		},
		toRecords() {
			// this.$router.push(`/record?id=${ruleForm.form}`)
			
			this.$router.push({ path: '/record', query: { phone: this.ruleForm.phone } })
		}
	},

}
</script>
<style lang='scss'  scoped>
//@import url(); 引入公共css类
#app {
	max-width: 100%;
}

.index-page {
	padding: 1.5vh;
	box-sizing: border-box;
	overflow: hidden;
	text-align: center;
	font-weight: bold;
	// letter-spacing: 4px;
	background: url("../../assets/background-research_1.png") no-repeat no-repeat;
	background-size: contain;
	width: 100%;
	min-height: 100vh;
	position: absolute;
	background: -webkit-linear-gradient(left top, #3481ce, #539de7);


	.index-logo {
		// height: 20vh;
		display: block;
		padding: 0 20px;

		img {
			display: block;
			width: 100%;
			margin: 0px auto;
			padding-top: 80px;
			max-width: 1000px;
		}
	}
}

.page-header-title {
	box-sizing: border-box;
	position: relative;
	overflow: hidden;
	height: 100px;
	// padding: 50px 0 130px 0;
	padding-top: 16px;  //30
	padding-bottom: 0px;
	width: 100%;
	text-align: center;
	font-weight: bold;
	letter-spacing: 1px;
}

.form_box {
	height: 380px;
	margin-top: -30px; //0px //-16px
	// min-height: 30vh;
}

.index-content {
	width: 100%;
	min-height: calc(100vh - 3.5vh);
	margin: 0 auto;
	border-radius: 20px;
	background: #ffffff;
}

.form_title {
	text-align: start;
	width: calc(50vw + 81px);
	margin: 20px auto;
	font-weight: bold;
	font-size: 15px;
}

.record {
	text-align: end;
	margin-top: 0px;
	margin: 0 auto;
	font-size: 14px;

	span {
		padding: 0px 0;
	}
}

.el-button {
	width: 140px;
	// padding: 12px 40px;
}

.bottom_box {
	position: absolute;
	bottom: 8vh;
	left: calc(50% - 70px);
}

.el-button--primary {
	background-color: #1989fa;
}

.el-icon-right {
	margin-left: 14px;
}

.el-icon-arrow-right {
	margin: 0;
	font-size: 13px;
}

@media screen and (max-width: 300px) {
	.page-header-title {
		font-size: 17px;
		// padding-top: 50px;
	}
}

@media screen and (max-width: 355px) and(min-width: 300px) {
	.page-header-title {
		font-size: 18px;
		// padding-top: 50px;
	}
}

@media screen and (min-width: 370px) {
	.page-header-title {
		font-size: 22px;
		// padding-top: 50px;
	}
}
</style>
<style lang="scss">
.index-page .el-form-item__content {
	width: 58vw;
}
</style>