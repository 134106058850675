const myStorage = {
    //存储
    set(key, value) {
        localStorage.setItem(key, JSON.stringify(value));
        console.log(` ==== 写入本地缓存 `, key, value);
    },
    //取出数据
    get(key) {
        try {
            const value = localStorage.getItem(key);
            if (value === null || value === undefined || value === "") {
                return null;
            }
            return JSON.parse(localStorage.getItem(key));
        } catch (err) {
            return null
        }
    },
    // 删除数据
    remove(key) {
        localStorage.removeItem(key);
    },
    // 删除数据
    clear() {
        localStorage.clear();
        console.log(` ==== 清理本地缓存`, );
    }
}

export default myStorage